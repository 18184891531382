body {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	background-color: black;
	margin: 0;
	padding: 0;
	font-weight: normal;
	font-size: .9vmax;
	color: white;
}

a {
	color: rgb(192, 192, 192);
}

a:hover {
	color: rgb(255, 255, 255);
}

table {
	margin: 0px;
	padding: 0px 0px 0px 0px;
	border-collapse: collapse
}

th {
	font-size: 16px;
	padding: 0px 2px 0px 2px;
	font-variant: small-caps;
	background-color: rgba(0, 0, 0, 0);
	font-weight: 300;
	overflow: visible;
	text-wrap: nowrap;
}


td {
	border-width: 1px;
	border-color: rgb(255, 255, 255);
	border-style: none;
	padding: 0 0 0 0;
}

.simpleStatsPadding {
	padding: 0 5px 0 5px;
}

input {
	background-color: rgba(0, 0, 0, 0.7);
	border-width: 1px;
	color: rgb(192, 162, 81);
	font-variant-caps: all-small-caps;
}

.filterHidden {
	opacity: 0%;
}


button {
	color: rgb(199, 199, 199);
	font-variant: all-small-caps;
	font-style: bold;
	margin: 5px;
	background-color: rgba(15, 15, 15, 0.37);
	border-radius: .5vmax;
	border-width: .1vmax;
	border-style: solid;
	border-color: rgb(180, 180, 180);
	padding: .1vh .1vw;
	text-wrap: nowrap;
	overflow: visible;
}

button:hover {
	color: rgb(255, 255, 255);
}

button:active {
	color: rgb(151, 151, 151);
	background-color: black;
}

select {
	background-color: rgba(15, 15, 15, 0.37);
	color: rgb(170, 155, 114);
	border-radius: .5vmax;
	border-width: .1vmax;
	border-style: solid;
	border-color: rgb(180, 180, 180);
	font-variant-caps: all-small-caps;
}

option {
	color: white;
	background-color: rgb(15, 15, 15);
}

.view {
	position: absolute;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.322);
	border-color: rgb(255, 255, 255);
	border-style: solid;
	border-radius: 5px;
	box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.304);
	overflow: auto;
}

.viewHeader {
	background-color: rgba(0, 0, 0, 0.664);
	box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.304);
	right: 5px;
	height: 2vmax;
	margin: 5px;
	border-width: 1px;
	border-radius: 5px;
	border-color: black;
	border-style: solid;
	display: flex;
	align-items: center;
	flex-direction: row;
	overflow: hidden;
}


.viewContent {
	border-radius: 5px;
	display: flex;
	justify-content: space-evenly;
	overflow: inherit;
	background-color: #000000aa;
	position: inherit;
	margin: 5px;
	left: 0px;
	right: 0px;
	top: 2.4vmax;
	bottom: 2px;
	overflow-x: hidden;
	scrollbar-color: rgba(255, 255, 255, 0.281) rgba(12, 12, 12, 0.445);
}

.headerElement {
	font-size: 1.3vmin;
	padding: 0 .3vw 0 .3vw;
	font-variant: all-small-caps;
	font-style: bold;
	margin: 2px;
	color: rgba(207, 207, 207, 0.699);
}

.unitTable {
	width: 100%;
	height: 0%;
}

.bgImgSelectDiv {
	margin-top: 20%;
}

.plainBGButton {
	margin-left: 0%;
}

.countdownDiv {
	margin-top: 20%;
}

.unitTableHeader {
	font-size: 1.5vmin;
	font-weight: 500;
	color: rgb(214, 214, 214);
	text-align: center;
	line-height: 1vmin;
	height: 1vmin;
}

.unitTableSmalltext {
	font-size: 0.5vmax;
}

.unitTableCell {
	background-color: #88000011;
	text-align: center;
	text-shadow: 2px 2px 1px black;
	font-size: 1.5vmin;

}

.mouseOverHighlighted {
	outline: inset;
	outline-color: rgba(255, 221, 221, 0.425);
	outline-style: dotted;
	outline-offset: -1px;
	outline-width: 1px;
}

.unitTableCellAlt {
	background-color: #c5000017;
}

.unitTableHeaderImage {
	height: 1.2vmax;
}

.unitTableImage {
	height: 4vmin;
	width: auto;
}

.unitTableImageMedium {
	height: 3.5vmin;
	width: auto;
}

.unitTableImageSmall {
	height: 2.5vmin;
	width: auto;
}


.unitTableCellTraits {
	overflow-x: visible;
}

.tableAddUnitButton {
	font-style: bold;
	font-size: 3vmin;
	height: 4vmin;
	width: 4vmin;
	vertical-align: middle;
	text-align: center;
	padding: 0;
}

#statsContent {
	position: absolute;
	border: none;
	border-color: rgb(255, 255, 255);
	overflow: hidden;
}

#statsUnitBottomContainer {
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: left;
	border-width: 1px;
	height: 5vmin;
	background-color: rgba(0, 0, 0, 0.5);
	left: 5vmin;
	bottom: 2vmin;
	z-index: 10;
	font-size: 2vmin;
	font-variant: small-caps;
}

.statsUnitResourceDiv {
	padding: .5vmin;
	position: relative;
	font-weight: 700;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: left;
}

.statsUnitResourceValue {
	padding: .5vmin;
}

#statsUnitName {
	justify-content: left;
	font-size: 3vmin;
	font-weight: 800;
	position: relative;
	text-wrap: nowrap;
	overflow: hidden;
	padding-right: 1vmin;
	padding-top: .2vmin;
}

.statsUnitBuildingImg {
	height: 3.5vmin;
	width: 3.5vmin;
}

.statsUnitResourceImg {
	padding: .7vmin 0vmin 0vmin 0vmin;
	height: 2.5vmin;
	width: 2.5vmin;
}

#unitOfficialLinkDiv {
	position: absolute;
	right: 0%;
	bottom: 0%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 200;
}

#statsUnitEnergyValue {
	color: rgb(68, 140, 233);
}

#statsUnitBandwidthValue {
	color: rgb(233, 220, 43);
}

#statsUnitMatterValue {
	color: rgb(255, 58, 58);
}

#unitVideo {
	position: absolute;
	min-height: 100%;
	min-width: 100%;
	width: 100%;
	height: auto;
	overflow: hidden;
	object-fit: cover;
	z-index: 0;
	border-width: 5px;
	border: solid;
	border-color: rgb(255, 255, 255);
	/* Center the video */
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#statsUnitRightContainer {
	position: absolute;
	width: 50%;
	top: 1vmin;
	right: 1vmin;
	bottom: 8vmin;
	border-width: 1px;
	border: none;
	border-color: rgb(255, 255, 255);
	background-color: rgba(0, 0, 0, 0.185);
	z-index: 10;
}

#statsUnitTraitsContainer {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 45%;
	top: 1vmin;
	bottom: 1vmin;
	left: 1vmin;
	background-color: rgba(0, 0, 0, 0.25);
	z-index: 10;
	font-variant: small-caps;
	font-size: .8vmax;
}

.statsUnitTypeDiv {
	opacity: 80%;
	height: 20vmin;
	line-height: 5vmin;
	font-size: 2.5vmin;
	font-weight: 800;
}

.statsUnitTraitsDiv {
	opacity: 98%;
	display: flex;
	font-weight: 600;
	align-items: center;
	height: 5vmin;
	line-height: 3vmin;
	overflow: visible;
	text-wrap: balance;
}


.statsUnitTraitImg {
	width: 1.5vmax;
	padding-left: 3;
}

#statsUnitChartContainer {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 23%;
	top: 1vmin;
	bottom: 1vmin;
	right: 25%;
	border-width: 1px;
	border: none;
	border-color: rgb(255, 0, 0);
	background-color: rgba(0, 0, 0, 0.25);
	z-index: 10;
}

.statsUnitChartDiv {
	position: relative;
	left: 2%;
	background-color: rgba(0, 0, 0, 0.5);
	height: 13%;
	width: 98%;
	border: none;
	border-color: rgba(255, 255, 255, 0.479);
	z-index: 10;
	border-width: 1px;
}

#statsUnitRankDiv {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 100%;
	height: 100%;
	left: 100%;
	border: none;
	border-width: 1px;
	background-color: rgba(0, 0, 0, 0.25);
	z-index: 10;
}

.statsUnitRankTextDiv {
	position: relative;
	left: 2%;
	background-color: rgba(0, 0, 0, 0.5);
	height: 13%;
	width: 98%;
	border: none;
	border-color: rgba(255, 255, 255, 0.479);
	z-index: 10;
	border-width: 1px;
	font-size: 1.5vmin;
	font-weight: 700;
	font-variant: small-caps;
	line-height: 250%;
	text-wrap: nowrap;
}

.barchart {
	position: absolute;
}

.statsUnitChartImages {
	position: relative;
	top: 10%;
	height: 50%;

}

.menuCredits {
	font-size: 11px;
	margin: 0 0 5 0;

}

.comparisonChartContainer {
	position: relative;
	display: flex;
	flex-shrink: 5;
	flex-direction: row;
	height: 100%;
	width: 100%;

	background-color: rgba(0, 0, 0, 0.192);
	justify-content: space-evenly;

}

.starchart {
	border: none;
	border-width: 1px;
	border-color: rgba(192, 162, 81, 0.205);
	width: 100px;
	background-image: url('images/stats/starchartbg.png');
	background-position: center;
	background-size: cover;
}

sup {
	vertical-align: top;
	font-size: 6px;
	position: relative;
	top: -0.5em;
}



#wrapper {
	position: absolute;
	background-image: url('images/bg/19.jpg');
	background-position: center center;
	background-size: cover;
	background-color: rgb(72, 77, 82);
	border-width: 1px;
	border-color: white;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	overflow: hidden;
	display: flex;
}

#app {
	position: relative;
	flex-grow: 1;
	margin: 5px;
}

#sidebar {
	position: relative;
	font-size: small;
	font-variant: small-caps;
	font-style: bold;
	height: 100%;
	float: left;
	background-color: rgba(8, 8, 10, 0.596);
	box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
	;
}

.sidebarContracted {
	padding: 0;
	width: 50px;
	transition: width .1s;
}

.sidebarExpanded {
	padding: .5vmin;
	width: 200px;
	transition: width .1s;

}

#sidebarLogoImg {
	width: 100%;
	opacity: 100%;
	transform: translate(2%, 0);
}

#sidebarLogoImg {
	padding: 15 0 0 0;
	width: 100%;
	opacity: 100%;
}

#sidebarTitleDiv {
	padding: 10px;
}

#sidebarTitleDivV {
	transform: rotate(90deg) translate(30px);
}

.sidebarFooterDiv {
	position: absolute;
	bottom: 0px;
	padding: 5px;
	font-size: 12px;
	color: rgba(255, 255, 255, 0.671);
	text-shadow: 2px 2px 1px black;
}

.sidebarExtraSmallFont {
	padding: 5 0 5 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 8px;
	font-variant: normal;
	color: rgba(255, 255, 255, 0.671);
	font-weight: 600;
	text-shadow: 2px 2px 1px black;
}

.rankchartsuperscript {
	font-variant: small-caps;
	font-size: x-small;
	vertical-align: super;
}

.buttonContainer {
	display: flex;
	flex-direction: column;
}

#toggleSidebarButton {
	padding: 0 0 0 0;
	margin: 0 0 0 0;
	position: relative;
	font-size: xx-large;
	height: 30px;
	width: 30px;
	top: 10px;
	left: 50%;
	background-image: 'images/burgericon.png';
	background-position: 0 10;
	transform: translate(-40%, 0);
}

.sidebarBtnImg {
	padding: 0 0 0 0;
	margin: 4 0 8 0;
	height: 60%;
}

#unitView-h {
	overflow: auto;
	margin: 3px;
	right: 50%;
	left: 0px;
	top: 0px;
	bottom: 0px;
	border: solid;
	border-color: black;
	border-width: 2px;
}

#stratView-h {
	overflow: auto;
	margin: 3px;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	border: solid;
	border-color: black;
	border-width: 2px;
}

#deckView-h {
	position: absolute;
	margin: 3px;
	left: 50%;
	top: 0px;
	right: 0px;
	bottom: 50%;
}

#statsView-h {
	margin: 3px;
	left: 50%;
	top: 50%;
	right: 0px;
	bottom: 0px;
	overflow: hidden;
}

#unitView-v {
	overflow: auto;
	margin: 0;
	left: 0;
	right: 0;
	top: 0;
	bottom: 60.5%;
	margin: 0;
	left: 0;
	right: 0;
	top: 0;
	bottom: 60.5%;
}


#deckView-v {
	margin: 0;
	height: 29.5%;
	left: 0;
	right: 0;
	top: 40%;
	margin: 0;
	height: 29.5%;
	left: 0;
	right: 0;
	top: 40%;
}

#statsView-v {
	margin: 0;
	margin: 0;
	height: 30%;
	left: 0;
	right: 0;
	top: 70%;
	bottom: 0;
	left: 0;
	right: 0;
	top: 70%;
	bottom: 0;
	overflow: hidden;
}


#unitTableHead {
	position: sticky;
	background-color: rgba(0, 0, 0, 0.623);
	background-color: rgba(0, 0, 0, 0.623);
	top: -1px;
	height: 30px;
}

#unitTableBody {
	top: 30px;
}

#deckContent {
	background-color: rgba(5, 1, 66, 0.685);
	border: dotted;
	border-width: 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	overflow: visible;
}

.deckSlotImage {
	width: 100%;
	height: 100%;
	opacity: 95%;
	resize: none;
}

.deckContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 45%;
	height: 95%;
	border-color: rgb(2, 1, 90);
	border-width: 1px;
	border-style: solid;
	overflow-y: hidden;
	overflow-x: hidden;
}

.deckContainerActive {
	border-color: rgb(192, 162, 81, .5);
}

.deckSaveClearBtn {
	width: fit-content;

	font-size: 1.3vmin;
	padding: 0 .3vw 0 .3vw;
	font-variant: all-small-caps;
	font-style: bold;
	margin: 2px;
	color: rgba(207, 207, 207, 0.699);
}

.deckNameDiv {
	display: flex;
	flex-direction: row;
	justify-content: center;
}


.deckNameInput {
	width: 63%;
	font-size: 1.3vmin;
	padding: 0 .3vw 0 .3vw;
	font-variant: all-small-caps;
	font-style: bold;
	margin: 2px;
	color: rgba(207, 207, 207, 0.699);
}

#decklistDropdown {
	min-width: 15vmin;
	max-width: 18vmin;
}

.deckSlotContainer {
	width: 100%;
	height: fit-content;
	margin: 5 0 5 0;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
}

.unitDeckSlotDiv {
	width: 19%;
	border-color: rgba(2, 0, 36, 0.781);
	border-width: 2px;
	border-style: solid;
	margin: 1%;
	overflow: hidden;
}

.unitDeck1SlotDivFilled {
	border-color: rgb(19, 104, 19);
}

.unitDeck2SlotDivFilled {
	border-color: rgb(104, 24, 24);
}

.deckStatsDiv {
	font-weight: 350;
	font-size: 1.5vmin;
	line-height: 80%;
	width: 98%;
	height: 50%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	border: 0px;
	border-style: solid;
	padding: 5px;
}

.statDiv {
	height: 15%;
	font-variant: small-caps;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.308);
}

.complexStatDiv {
	justify-content: left;
	width: 100%;
}

.resourceStatDiv {
	width: 30%;
}

.unitStatDiv {
	width: 25%;
}

.deckStatsImg {
	width: 2vmin;
	padding: .5vmin;
}

.resourceStatImg {
	width: 2vmin;
	padding: .5vmin;
}

.deckStatsImgAbility {
	width: 7%;
}

.deckStatsImgTraits {
	width: 5.8%;
}

.deckStatsImgManf {
	position: relative;
	width: 7%;
}

.linksDiv {
	flex: auto;
	position: relative;
}

.linksContent {
	position: inherit;
	color: grey;
}

.selected {
	color: rgb(192, 162, 81);
}

#unitCardContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-content: flex-start;
	flex-basis: 97%;
	gap: 2%;
	width: 100%;
	height: 100%;

}

.unitCardText {
	text-shadow: 2px 2px 1px black;
	font-weight: 700;
}

.unitCardName {
	position: absolute;
	left: 4%;
	top: 12%;
	color: rgb(219, 219, 219);
	margin: 0%;
	width: 90%;
	overflow: visible;
	border: solid;
	border-width: 1px;
	border-style: dashed;
	border-color: rgba(77, 77, 77, 0.342);
	text-align: center;
}

.unitCard {
	overflow: hidden;
	position: relative;
	background-color: rgba(0, 0, 0, 0.493);
	width: 140px;
	height: 200px;
	border-radius: 5%;
	font-size: 16px;
	z-index: 0;
}

.unitCardImage {
	position: absolute;
	opacity: 88%;
	width: 70%;
	left: 15%;
	top: 30%;
}

.unitCardBuilding {
	opacity: 90%;
	position: absolute;
	width: 30%;
	right: 0%;
	bottom: 2%;
}

.unitCardMatter {
	position: absolute;
	top: 0;
	color: rgb(255, 58, 58);
	margin: 2%;
	width: 30%;
	height: 10%;
	text-align: center;
}

.unitCardBandwidth {
	position: absolute;
	left: 32%;
	top: 0;
	color: rgb(233, 220, 43);
	margin: 2%;
	width: 30%;
	height: 10%;
	text-align: center;
}

.unitCardEnergy {
	position: absolute;
	right: 0;
	top: 0;
	color: rgb(68, 140, 233);
	margin: 2%;
	width: 30%;
	height: 10%;
	text-align: center;
}

.unitCardType {
	position: absolute;
	left: 10%;
	top: 20%;
	color: rgba(255, 255, 255, 0.507);
	text-shadow: none;
	width: 0%;
	height: 10%;
	font-variant: small-caps;
	font-size: large;
	text-wrap: nowrap;
	transform: rotate(90deg);
}

.unitCardTrait {
	width: 90%;
	padding: 0 0 30% 0;
}

.unitCardTraits {
	position: absolute;
	top: 26%;
	left: 80%;
	right: 4%;
}

.unitCardManufacturer {
	position: relative;
	top: 83%;
	bottom: 0%;
	height: 10%;
	width: 60%;
	left: 10%;
	opacity: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	filter: blur(0.5px);
	z-index: -1;
}

.outOfDateBanner {
	position: relative;
	font-size: 1vh;
	margin: 5;
	height: 1.5vh;
	color: rgb(255, 255, 255);
	background-color: rgb(0, 0, 0);
	text-wrap: nowrap;
	z-index: 10;
	font-weight: 600;
	transform: translate(0, -75%);
	text-decoration: underline;
}

.socialsImg {
	width: 1.5vmin;
	padding: 0 0 0 10;
}

.stratViewTimeInput {
	width: 100%;
}

.stratTimeContainer {
	display: flex;
	flex-direction: row;

	width: 90%;
	height: fit-content;
	border: none;
	border-width: 1px;
}

.stratButtonsContainer {
	display: flex;
	width: 90%;
	flex-direction: row;
	border: none;
	border-width: 1px;
}

.stratResourceContainer {
	display: flex;
	width: 20%;
	height: 100%;
	justify-content: space-evenly;
	flex-direction: row;
	border: none;
	border-width: 1px;
}

.stratResourceDiv {
	display: flex;
	flex-direction: row;
	padding: 3% 0 0 0;
	border: none;
	border-width: 1px;
}

.stratResourceImg {
	height: 2vmin;
}

#stratContent {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.stratChartContainer {
	height: 80%;
	width: 90%;
	border: solid;
	border-width: 1px;
}

.stratUnitsContainer {
	display: flex;
	flex-direction: row;
	height: 80%;
	width: 90%;
	border: solid;
	border-width: 1px;
}

#stratChart {
	position: relative;
	width: 100%;
	height: 100%;
}

.stratUnitImage {
	height: 2vmin;
}

.stratUnitButtonRed {
	background-color: red;
}