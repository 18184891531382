body {
  color: #fff;
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: .9vmax;
  font-weight: normal;
}

a {
  color: silver;
}

a:hover {
  color: #fff;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}

th {
  font-variant: small-caps;
  text-wrap: nowrap;
  background-color: #0000;
  padding: 0 2px;
  font-size: 16px;
  font-weight: 300;
  overflow: visible;
}

td {
  border: 1px #fff;
  padding: 0;
}

.simpleStatsPadding {
  padding: 0 5px;
}

input {
  color: #c0a251;
  background-color: #000000b3;
  border-width: 1px;
  font-variant-caps: all-small-caps;
}

.filterHidden {
  opacity: 0;
}

button {
  color: #c7c7c7;
  font-variant: all-small-caps;
  font-style: bold;
  text-wrap: nowrap;
  background-color: #0f0f0f5e;
  border: .1vmax solid #b4b4b4;
  border-radius: .5vmax;
  margin: 5px;
  padding: .1vh .1vw;
  overflow: visible;
}

button:hover {
  color: #fff;
}

button:active {
  color: #979797;
  background-color: #000;
}

select {
  color: #aa9b72;
  background-color: #0f0f0f5e;
  border: .1vmax solid #b4b4b4;
  border-radius: .5vmax;
  font-variant-caps: all-small-caps;
}

option {
  color: #fff;
  background-color: #0f0f0f;
}

.view {
  border: 1px solid #fff;
  border-radius: 5px;
  position: absolute;
  overflow: auto;
  box-shadow: 3px 3px 3px #0000004e;
}

.viewHeader {
  background-color: #000000a9;
  border: 1px solid #000;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  height: 2vmax;
  margin: 5px;
  display: flex;
  right: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 3px #0000004e;
}

.viewContent {
  overflow: inherit;
  position: inherit;
  scrollbar-color: #ffffff48 #0c0c0c71;
  background-color: #000a;
  border-radius: 5px;
  justify-content: space-evenly;
  margin: 5px;
  display: flex;
  inset: 2.4vmax 0 2px;
  overflow-x: hidden;
}

.headerElement {
  font-variant: all-small-caps;
  font-size: 1.3vmin;
  font-style: bold;
  color: #cfcfcfb2;
  margin: 2px;
  padding: 0 .3vw;
}

.unitTable {
  width: 100%;
  height: 0%;
}

.bgImgSelectDiv {
  margin-top: 20%;
}

.plainBGButton {
  margin-left: 0%;
}

.countdownDiv {
  margin-top: 20%;
}

.unitTableHeader {
  color: #d6d6d6;
  text-align: center;
  height: 1vmin;
  font-size: 1.5vmin;
  font-weight: 500;
  line-height: 1vmin;
}

.unitTableSmalltext {
  font-size: .5vmax;
}

.unitTableCell {
  text-align: center;
  text-shadow: 2px 2px 1px #000;
  background-color: #8001;
  font-size: 1.5vmin;
}

.mouseOverHighlighted {
  outline-offset: -1px;
  outline: 1px dotted #ffdddd6c;
}

.unitTableCellAlt {
  background-color: #c5000017;
}

.unitTableHeaderImage {
  height: 1.2vmax;
}

.unitTableImage {
  width: auto;
  height: 4vmin;
}

.unitTableImageMedium {
  width: auto;
  height: 3.5vmin;
}

.unitTableImageSmall {
  width: auto;
  height: 2.5vmin;
}

.unitTableCellTraits {
  overflow-x: visible;
}

.tableAddUnitButton {
  font-style: bold;
  vertical-align: middle;
  text-align: center;
  width: 4vmin;
  height: 4vmin;
  padding: 0;
  font-size: 3vmin;
}

#statsContent {
  border: #fff;
  position: absolute;
  overflow: hidden;
}

#statsUnitBottomContainer {
  z-index: 10;
  font-variant: small-caps;
  background-color: #00000080;
  border-width: 1px;
  flex-direction: row;
  justify-content: left;
  height: 5vmin;
  font-size: 2vmin;
  display: flex;
  position: absolute;
  bottom: 2vmin;
  left: 5vmin;
}

.statsUnitResourceDiv {
  flex-flow: row;
  justify-content: left;
  padding: .5vmin;
  font-weight: 700;
  display: flex;
  position: relative;
}

.statsUnitResourceValue {
  padding: .5vmin;
}

#statsUnitName {
  text-wrap: nowrap;
  justify-content: left;
  padding-top: .2vmin;
  padding-right: 1vmin;
  font-size: 3vmin;
  font-weight: 800;
  position: relative;
  overflow: hidden;
}

.statsUnitBuildingImg {
  width: 3.5vmin;
  height: 3.5vmin;
}

.statsUnitResourceImg {
  width: 2.5vmin;
  height: 2.5vmin;
  padding: .7vmin 0 0;
}

#unitOfficialLinkDiv {
  z-index: 200;
  background-color: #00000080;
  position: absolute;
  bottom: 0%;
  right: 0%;
}

#statsUnitEnergyValue {
  color: #448ce9;
}

#statsUnitBandwidthValue {
  color: #e9dc2b;
}

#statsUnitMatterValue {
  color: #ff3a3a;
}

#unitVideo {
  object-fit: cover;
  z-index: 0;
  border: solid #fff;
  width: 100%;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

#statsUnitRightContainer {
  z-index: 10;
  background-color: #0000002f;
  border: #fff;
  width: 50%;
  position: absolute;
  top: 1vmin;
  bottom: 8vmin;
  right: 1vmin;
}

#statsUnitTraitsContainer {
  z-index: 10;
  font-variant: small-caps;
  background-color: #00000040;
  flex-direction: column;
  justify-content: flex-start;
  width: 45%;
  font-size: .8vmax;
  display: flex;
  position: absolute;
  top: 1vmin;
  bottom: 1vmin;
  left: 1vmin;
}

.statsUnitTypeDiv {
  opacity: .8;
  height: 20vmin;
  font-size: 2.5vmin;
  font-weight: 800;
  line-height: 5vmin;
}

.statsUnitTraitsDiv {
  opacity: .98;
  text-wrap: balance;
  align-items: center;
  height: 5vmin;
  font-weight: 600;
  line-height: 3vmin;
  display: flex;
  overflow: visible;
}

.statsUnitTraitImg {
  width: 1.5vmax;
  padding-left: 3px;
}

#statsUnitChartContainer {
  z-index: 10;
  background-color: #00000040;
  border: red;
  flex-direction: column;
  justify-content: space-around;
  width: 23%;
  display: flex;
  position: absolute;
  top: 1vmin;
  bottom: 1vmin;
  right: 25%;
}

.statsUnitChartDiv {
  z-index: 10;
  background-color: #00000080;
  border: 1px #ffffff7a;
  width: 98%;
  height: 13%;
  position: relative;
  left: 2%;
}

#statsUnitRankDiv {
  z-index: 10;
  background-color: #00000040;
  border: 1px;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 100%;
}

.statsUnitRankTextDiv {
  z-index: 10;
  font-variant: small-caps;
  text-wrap: nowrap;
  background-color: #00000080;
  border: 1px #ffffff7a;
  width: 98%;
  height: 13%;
  font-size: 1.5vmin;
  font-weight: 700;
  line-height: 250%;
  position: relative;
  left: 2%;
}

.barchart {
  position: absolute;
}

.statsUnitChartImages {
  height: 50%;
  position: relative;
  top: 10%;
}

.menuCredits {
  margin: 0 0 5px;
  font-size: 11px;
}

.comparisonChartContainer {
  background-color: #00000031;
  flex-direction: row;
  flex-shrink: 5;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.starchart {
  background-image: url("starchartbg.2db6094a.png");
  background-position: center;
  background-size: cover;
  border: 1px #c0a25134;
  width: 100px;
}

sup {
  vertical-align: top;
  font-size: 6px;
  position: relative;
  top: -.5em;
}

#wrapper {
  background-color: #484d52;
  background-image: url("19.9bd8c9d9.jpg");
  background-position: center;
  background-size: cover;
  border-width: 1px;
  border-color: #fff;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

#app {
  flex-grow: 1;
  margin: 5px;
  position: relative;
}

#sidebar {
  font-variant: small-caps;
  font-size: small;
  font-style: bold;
  float: left;
  background-color: #08080a98;
  height: 100%;
  position: relative;
  box-shadow: 2px 2px 2px 1px #0003;
}

.sidebarContracted {
  width: 50px;
  padding: 0;
  transition: width .1s;
}

.sidebarExpanded {
  width: 200px;
  padding: .5vmin;
  transition: width .1s;
}

#sidebarLogoImg {
  opacity: 1;
  opacity: 1;
  width: 100%;
  padding: 15px 0 0;
  transform: translate(2%);
}

#sidebarTitleDiv {
  padding: 10px;
}

#sidebarTitleDivV {
  transform: rotate(90deg)translate(30px);
}

.sidebarFooterDiv {
  color: #ffffffab;
  text-shadow: 2px 2px 1px #000;
  padding: 5px;
  font-size: 12px;
  position: absolute;
  bottom: 0;
}

.sidebarExtraSmallFont {
  font-variant: normal;
  color: #ffffffab;
  text-shadow: 2px 2px 1px #000;
  padding: 5px 0;
  font-family: Times New Roman, Times, serif;
  font-size: 8px;
  font-weight: 600;
}

.rankchartsuperscript {
  font-variant: small-caps;
  vertical-align: super;
  font-size: x-small;
}

.buttonContainer {
  flex-direction: column;
  display: flex;
}

#toggleSidebarButton {
  background-image: "images/burgericon.png";
  background-position: 0 10px;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  font-size: xx-large;
  position: relative;
  top: 10px;
  left: 50%;
  transform: translate(-40%);
}

.sidebarBtnImg {
  height: 60%;
  margin: 4px 0 8px;
  padding: 0;
}

#unitView-h {
  border: 2px solid #000;
  margin: 3px;
  inset: 0 50% 0 0;
  overflow: auto;
}

#stratView-h {
  border: 2px solid #000;
  margin: 3px;
  inset: 0;
  overflow: auto;
}

#deckView-h {
  margin: 3px;
  position: absolute;
  inset: 0 0 50% 50%;
}

#statsView-h {
  margin: 3px;
  inset: 50% 0 0 50%;
  overflow: hidden;
}

#unitView-v {
  margin: 0;
  inset: 0 0 60.5%;
  overflow: auto;
}

#deckView-v {
  height: 29.5%;
  margin: 0;
  top: 40%;
  left: 0;
  right: 0;
}

#statsView-v {
  height: 30%;
  margin: 0;
  inset: 70% 0 0;
  overflow: hidden;
}

#unitTableHead {
  background-color: #0000009f;
  height: 30px;
  position: sticky;
  top: -1px;
}

#unitTableBody {
  top: 30px;
}

#deckContent {
  background-color: #050142af;
  border: 0 dotted;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  overflow: visible;
}

.deckSlotImage {
  opacity: .95;
  resize: none;
  width: 100%;
  height: 100%;
}

.deckContainer {
  border: 1px solid #02015a;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  height: 95%;
  display: flex;
  overflow: hidden;
}

.deckContainerActive {
  border-color: #c0a25180;
}

.deckSaveClearBtn {
  font-variant: all-small-caps;
  font-size: 1.3vmin;
  font-style: bold;
  color: #cfcfcfb2;
  width: fit-content;
  margin: 2px;
  padding: 0 .3vw;
}

.deckNameDiv {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.deckNameInput {
  font-variant: all-small-caps;
  font-size: 1.3vmin;
  font-style: bold;
  color: #cfcfcfb2;
  width: 63%;
  margin: 2px;
  padding: 0 .3vw;
}

#decklistDropdown {
  min-width: 15vmin;
  max-width: 18vmin;
}

.deckSlotContainer {
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: fit-content;
  margin: 5px 0;
  display: flex;
}

.unitDeckSlotDiv {
  border: 2px solid #020024c7;
  width: 19%;
  margin: 1%;
  overflow: hidden;
}

.unitDeck1SlotDivFilled {
  border-color: #136813;
}

.unitDeck2SlotDivFilled {
  border-color: #681818;
}

.deckStatsDiv {
  border: 0 solid;
  flex-flow: wrap;
  justify-content: space-between;
  width: 98%;
  height: 50%;
  padding: 5px;
  font-size: 1.5vmin;
  font-weight: 350;
  line-height: 80%;
  display: flex;
}

.statDiv {
  font-variant: small-caps;
  background-color: #0000004f;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 15%;
  display: flex;
}

.complexStatDiv {
  justify-content: left;
  width: 100%;
}

.resourceStatDiv {
  width: 30%;
}

.unitStatDiv {
  width: 25%;
}

.deckStatsImg, .resourceStatImg {
  width: 2vmin;
  padding: .5vmin;
}

.deckStatsImgAbility {
  width: 7%;
}

.deckStatsImgTraits {
  width: 5.8%;
}

.deckStatsImgManf {
  width: 7%;
  position: relative;
}

.linksDiv {
  flex: auto;
  position: relative;
}

.linksContent {
  position: inherit;
  color: gray;
}

.selected {
  color: #c0a251;
}

#unitCardContainer {
  flex-flow: wrap;
  flex-basis: 97%;
  place-content: flex-start space-evenly;
  gap: 2%;
  width: 100%;
  height: 100%;
  display: flex;
}

.unitCardText {
  text-shadow: 2px 2px 1px #000;
  font-weight: 700;
}

.unitCardName {
  color: #dbdbdb;
  text-align: center;
  border: 1px dashed #4d4d4d57;
  width: 90%;
  margin: 0%;
  position: absolute;
  top: 12%;
  left: 4%;
  overflow: visible;
}

.unitCard {
  z-index: 0;
  background-color: #0000007e;
  border-radius: 5%;
  width: 140px;
  height: 200px;
  font-size: 16px;
  position: relative;
  overflow: hidden;
}

.unitCardImage {
  opacity: .88;
  width: 70%;
  position: absolute;
  top: 30%;
  left: 15%;
}

.unitCardBuilding {
  opacity: .9;
  width: 30%;
  position: absolute;
  bottom: 2%;
  right: 0%;
}

.unitCardMatter {
  color: #ff3a3a;
  text-align: center;
  width: 30%;
  height: 10%;
  margin: 2%;
  position: absolute;
  top: 0;
}

.unitCardBandwidth {
  color: #e9dc2b;
  text-align: center;
  width: 30%;
  height: 10%;
  margin: 2%;
  position: absolute;
  top: 0;
  left: 32%;
}

.unitCardEnergy {
  color: #448ce9;
  text-align: center;
  width: 30%;
  height: 10%;
  margin: 2%;
  position: absolute;
  top: 0;
  right: 0;
}

.unitCardType {
  color: #ffffff81;
  text-shadow: none;
  font-variant: small-caps;
  text-wrap: nowrap;
  width: 0%;
  height: 10%;
  font-size: large;
  position: absolute;
  top: 20%;
  left: 10%;
  transform: rotate(90deg);
}

.unitCardTrait {
  width: 90%;
  padding: 0 0 30%;
}

.unitCardTraits {
  position: absolute;
  top: 26%;
  left: 80%;
  right: 4%;
}

.unitCardManufacturer {
  opacity: 1;
  filter: blur(.5px);
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 60%;
  height: 10%;
  position: relative;
  top: 83%;
  bottom: 0%;
  left: 10%;
}

.outOfDateBanner {
  color: #fff;
  text-wrap: nowrap;
  z-index: 10;
  background-color: #000;
  height: 1.5vh;
  margin: 5px;
  font-size: 1vh;
  font-weight: 600;
  text-decoration: underline;
  position: relative;
  transform: translate(0, -75%);
}

.socialsImg {
  width: 1.5vmin;
  padding: 0 0 0 10px;
}

.stratViewTimeInput {
  width: 100%;
}

.stratTimeContainer {
  border: 1px;
  flex-direction: row;
  width: 90%;
  height: fit-content;
  display: flex;
}

.stratButtonsContainer {
  border: 1px;
  flex-direction: row;
  width: 90%;
  display: flex;
}

.stratResourceContainer {
  border: 1px;
  flex-direction: row;
  justify-content: space-evenly;
  width: 20%;
  height: 100%;
  display: flex;
}

.stratResourceDiv {
  border: 1px;
  flex-direction: row;
  padding: 3% 0 0;
  display: flex;
}

.stratResourceImg {
  height: 2vmin;
}

#stratContent {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.stratChartContainer {
  border: 1px solid;
  width: 90%;
  height: 80%;
}

.stratUnitsContainer {
  border: 1px solid;
  flex-direction: row;
  width: 90%;
  height: 80%;
  display: flex;
}

#stratChart {
  width: 100%;
  height: 100%;
  position: relative;
}

.stratUnitImage {
  height: 2vmin;
}

.stratUnitButtonRed {
  background-color: red;
}
/*# sourceMappingURL=index.35c01daa.css.map */
